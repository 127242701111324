<template>
	<div id="Battle_item" :class="status | ProcessName" @click="ToCombat" ref="boxItems">
		<div class="box_ceng" v-show="status == '2'"></div>
		<div class="Battle_item_B">
			<div class="player">
				<div class="player_item" v-for="(item,index) in PlayerArr" :key="index">
					<div class="player_pic ">
						<div  v-if="item != null" class="por">
							<img  :src="item.avatar | ImgFilter" alt="" class="one">
							<img v-if="item.anchor==1" src="../../../../assets/images/q2/pkin/userbac.png" alt="" class="poa">
						</div>
						<img v-else src="@/assets/images/q2/pk/addbtn.png">
					</div>
				</div>
			</div>
		</div>
	
		<div class="Battle_item_T">
			<div class="box_list">
				<div class="scrolls">
					<div class="box_item" v-for="(item,index) in game_arena_box" :key="index">
						<img :src="item.cover | ImgFilter" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="Battle_item_A">
			<!-- <div class="process">
				<img :src="status | ProcessImg" alt="">
				<p :style="status | ProcessSty">{{status | Process}}</p>
			</div> -->
			
			<div class="round">
				{{Detail.box_num}}
			</div>
			<div class="TotalPrice">
				<img src="@/assets/images/public/Gold.png" alt="">{{Detail.total_bean}}
				<span style="color:#FD6C0F">加入</span>
			</div>
		</div>
		
	</div>
</template>

<script>
	import { ArenaDetail } from '@/network/api.js'
	import { mapState } from 'vuex'
	export default{
		name:'Battle_item',
		props:{
			id:{
				type:Number,
				
			}
		},
		data(){
			return{
				Detail:{},//详情
				game_arena_box:[],//箱子列表
				status:'',//进程
				PlayerArr:[],//玩家列表
			}
		},
		// updated(){
		// 	setTimeout(() => {
		// 	if(100>this.Detail.total_bean*1){
		// 		this.$refs.boxItems.style.background= "url(" + require('../../../../assets/images/Vs/blue.png')+ ")"
		// 	} 
			
		// 	else if(500>this.Detail.total_bean*1){
		// 		this.$refs.boxItems.style.background="url(" + require('../../../../assets/images/Vs/propeo.png')+ ")"
		// 	}
		// 	else if(this.Detail.total_bean*1>500){
		// 		this.$refs.boxItems.style.background="url(" + require('../../../../assets/images/Vs/red.png')+ ")"
		// 	}
		// 	}, 600);
		// },
		mounted(){
			// setTimeout(() => {
			// if(100>this.Detail.total_bean*1){
			// 	this.$refs.boxItems.style.background= "url(" + require('../../../../assets/images/Vs/blue.png')+ ")"
			// } 
		
			// else if(500>this.Detail.total_bean*1){
			// 	this.$refs.boxItems.style.background="url(" + require('../../../../assets/images/Vs/propeo.png')+ ")"
			// }
			// else if(this.Detail.total_bean*1>500){
			// 	this.$refs.boxItems.style.background="url(" + require('../../../../assets/images/Vs/red.png')+ ")"
			// }
			// }, 600);
			
		},
		created(){
			this.GetArenaDetail()
		},
		
		methods:{
			//详情
			GetArenaDetail(){
				ArenaDetail(this.id).then((res) => {
					// console.log(res.data.data)
					this.Detail = res.data.data
					this.game_arena_box = res.data.data.game_arena_box
					this.status = res.data.data.status
					this.SeatScreening(res.data.data.user_num,res.data.data.game_arena_player)
					console.log(res.data.data.game_arena_player);
				})
			},
			
			//再次调用
			GetArenaDetailT(){
				ArenaDetail(this.id).then((res) => {
					this.Detail = res.data.data
					this.game_arena_box = res.data.data.game_arena_box
					if(res.data.data.status == 2){
						this.ProcessDelay()
					}else{
						this.status = res.data.data.status
					}
					this.SeatScreening(res.data.data.user_num,res.data.data.game_arena_player)
				})
			},
			
			//确定座位顺序
			SeatScreening(num,arr){				
				let A_Arr = []
				for(let i = 0; i < num; i++){
					A_Arr[i] = null
				}
				
				for(let k = 0; k < arr.length; k++){
					A_Arr[arr[k].seat] = arr[k]
				}
				this.PlayerArr = A_Arr
			},
			
			// 进程延迟(增加进行中状态)
			ProcessDelay(){
				this.status = 1 //进行
				setTimeout(()=>{
					this.status = 2 //结束
				},9000 * this.game_arena_box.length)
			},
			
			//路由跳转详情
			ToCombat(){
				this.$router.push({
					path:'/Combat',
					query:{
						id:this.id,
						type:this.status
					}
				})
			}
			
		},
		
		computed:{
			...mapState([
				'join',
				// 'end'
			])
		},
		
		watch:{
			join(v){
				if(v.game_arena_id == this.id){
					// console.log(v)
					this.GetArenaDetailT()
				}
			}
		},
		
		filters:{
			//class过滤
			ProcessName(v){//状态 0=>等待中 1=>进行中 2=>以结束
				switch (v) {
					case 0:
						return 'etc'
						break;
					case 1:
						return 'conduct'
						break;
					case 2:
						return 'end'
						break;
					default:
				}
			},
			
			//进程文本过滤
			Process(v){
				switch (v) {
					case 0:
						return '等待中'
						break;
					case 1:
						return '进行中'
						break;
					case 2:
						return '已结束'
						break;
					default:
				}
			},
			
			//进程字体颜色过滤
			ProcessSty(v){
				switch (v) {
					case 0:
						return 'color: #f1bc60'
						break;
					case 1:
						return 'color: #d42d4f;'
						break;
					case 2:
						return 'color: #ffffff;'
						break;
					default:
				}
			},
			
			//进程图标颜色过滤
			ProcessImg(v){
				switch (v) {
					case 0:
						return require('@/assets/images/Vs/etc.png')
						break;
					case 1:
						return require('@/assets/images/Vs/conduct.png')
						break;
					case 2:
						return require('@/assets/images/Vs/end.png')
						break;
					default:
				}
			}
		},
	}
</script>

<style lang="scss">
.por{
	position: relative;
	overflow: vsiibility;
	.one{
		border-radius: 50%;
	}
	.poa{
		position: absolute;
		top: -50%;
		right: -50%;
		width: 200% !important;
		height: 200% !important;
	}
}
	.Battle_list>div{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	#Battle_item{
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		width: 47%;
		height: 200px;
		overflow: hidden;
		margin-bottom: 20px;
		margin: 1%;
		background-size: 100% 100% !important;
		transition: all 1.5s;
		position: relative;
		align-content: center;
		.box_ceng{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba($color: #000, $alpha: .3);
		}
		&.etc{
			background: url('../../../../assets/images/985/pk/pklist.png') no-repeat center;
			// background: url(../../../../assets/images/Vs/etc_back.png) no-repeat -10px 0;
			background-size: 110% 100%;
			// background-size: 100% 100%;
		}
		&.conduct{
			background: url(../../../../assets/images/Vs/conduct_back.png) no-repeat -10px 0;
			background-size: 110% 100%;
		}
		&.end{
			// background-image: url(../../../../assets/images/Vs/hui.png)  !important;
			background: url('../../../../assets/images/985/pk/pklists.png') no-repeat center;
			background-size: 110% 100%;
		}
		// .box_cheng{
		// 	background: rgba($color: #000, $alpha: .3);
		// 	position: absolute;
		// 	left: 0;
		// 	top: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	background: rgba(0, 0, 0, 0.3);
		// }
		.Battle_item_A{
			margin: 0 auto;
			width: 100%;
			height: 40px;
			font-size: 12px;
			// background: rgba($color: #000000, $alpha: .2);
			// border-bottom: 1px solid rgba($color: #000000, $alpha: .3);
			// padding: 0 10px;
			box-sizing: border-box;
			display: flex;
			flex-wrap: nowrap;
			padding-top: 10px;
			align-items: center;
			.process{
				width: 60px;
				height: 100%;
				display: flex;
				align-items: center;
				margin-right: 5px;
				margin: 0 auto;
				img{
					width: 16px;
					vertical-align: middle;
					margin-right: 3px;
				}
				p{
					font-size: 12px;
					font-weight: bold;
				}
			}
			.box_list{
				width: 200px;
				height: 100%;
				display: flex;
				overflow: scroll;
				.box_item{
					width: 20px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						max-width: 100%;
						max-height: 100%;
						vertical-align: middle;
					}
				}
				
			}
			.round{
				width: 40px;
				height: 80%;
				margin-left: 5px;
				line-height: 25px;
				text-align: center;
				color: red;
				font-size: 14px;
				font-weight: 700;
				background:url('../../../../assets/images/q2/pk/newnumbac.png') no-repeat center;
				background-size: 100% 100%;
				// margin-top: 10px;
			}
		}
		.Battle_item_B{
			width: 100%;
			height: 50px;
			padding: 10px 10px;
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			
			.player{
				width: 100%;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				.player_item::after{
					background: none !important;
					// width: 0 !important;
					display: none !important;
				}
				.player_item:nth-child(2){
					// width: 100% !important;
					// text-align:center;
				}
				.player_item{
					height: 32px;
					display: flex;
					align-items: center;
					width: 30%;
					&::after{
						display: block;
						content: "";
						width: 20px;
						height: 20px;
						background: url(../../../../assets/images/Vs/vs.png) no-repeat center;
						background-size: contain;
						margin: 0 5px;
					}
					&:last-child::after{
						display: none;
					}
					.player_pic{
						margin: 0 auto;
						width: 30px;
						text-align: center;
						height: 30px;
						border-radius: 50%;
						// overflow: ;
						img{
							width: 100%;
							height: 100%;
							vertical-align: middle;
						}
					}
					
				}
			}
			
		}
		.Battle_item_T{
			width: 100%;
			height: 80px;
			// background: rgba($color: #000000, $alpha: .2);
			// border-bottom: 1px solid rgba($color: #000000, $alpha: .3);
			padding: 0px 10px;
			padding-top: 10px;
			box-sizing: border-box;
			display: flex;
			// overflow: scroll;
			.process{
				width: 60px;
				height: 100%;
				display: flex;
				align-items: center;
				margin-right: 5px;
				img{
					width: 16px;
					vertical-align: middle;
					margin-right: 3px;
				}
				p{
					font-size: 12px;
					font-weight: bold;
				}
			}
			.box_list{
				// width: 100%;
				height: 100%;
				display: flex;
				overflow: scroll;
				.scrolls{
					display: flex;
				}
				.box_item{
					// display: inline-block !important;
					width: 50px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					background: rgba(0, 0, 0, 0);
					// flex: 1 0 20%;
					// float: left;

					img{
						max-width: 100%;
						max-height: 100%;
						vertical-align: middle;
					}
				}
				
			}
			.round{
				width: 40px;
				height: 100%;
				margin-left: 5px;
				line-height: 40px;
				text-align: center;
				color: #fff;
				font-size: 14px;
			}
		}
		.TotalPrice{
				// width: 100%;
				height:20px;
				// margin-left: 5px;
				text-align: center;
				line-height: 25px;
				font-size: 12px;
				color: #f1bc60;
				font-weight: bold;
				margin: 0 auto;
				background: url('../../../../assets/images/985/pk/addbtn.png') no-repeat center;
				background-size: 100% 100%;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 5px;
				padding-right: 5px;
				img{
					width: 14px;
					height: 14px;
					vertical-align: middle;
					margin-bottom: 2px;
					margin: 0 auto;
					padding: 3px;
					padding-top: 0;
				}
			}
	}
</style>
